import "./styles.scss";

export const PAIN_DIAGRAM_TYPES = {
  // Full Body should go first to apply it by default
  full_body: {
    className: "fullBody",
    height: 150,
    label: "Full Body",
    width: 175,
  },
  foot_and_ankle: {
    className: "footAndAnkle",
    height: 575,
    label: "Foot and Ankle",
    width: 670,
  },
};

const PAIN_DIAGRAM_WIDTH = 400;

export default function initFBPainDiagram() {
  if (!window.fbControls) window.fbControls = [];
  window.fbControls.push((controlClass) => {
    class PainDiagram extends controlClass {
      static get definition() {
        return {
          icon: "&#128100;",
          i18n: {
            default: "Pain Diagram",
          },
        };
      }

      build() {
        const data = this.config.value || "";
        const diagramConfig = PAIN_DIAGRAM_TYPES[this.config.painDiagramType];
        const diagramHeight = diagramConfig.height * (PAIN_DIAGRAM_WIDTH / diagramConfig.width);
        const image = data ? this.markup(
          "img",
          null,
          {
            src: data,
            style: "width: 100%",
          },
        ) : null;
        this.field = this.markup(
          "div",
          image,
          {
            class: `fb-pain-diagram ${diagramConfig.className}`,
            style: `
              height: ${diagramHeight}px;
              width: ${PAIN_DIAGRAM_WIDTH}px
            `,
          },
        );

        return this.field;
      }
    }

    controlClass.register("painDiagram", PainDiagram);
    return PainDiagram;
  });
}
