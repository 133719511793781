import Log from "utils/logging";
import isPreviewMode from "utils/isPreviewMode";
import getCSRFToken from "./getCSRFToken";

const ERROR_500_LOCATION = "/pages/error_500";

const requestToServer = async ({ path, method = "GET", body, credentials = "same-origin" }) => {
  if (isPreviewMode() && method !== "GET") return {};

  const response = await fetch(path, {
    body: JSON.stringify(body),
    credentials,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      ...(method.toUpperCase() !== "GET" && headersWithCSRFToken()),
    },
    method,
  });

  if (response.status >= 500) {
    const uuid = response.headers.get("Error-UUID");
    window.location.href = uuid ? ERROR_500_LOCATION.concat("?uuid=", uuid) : ERROR_500_LOCATION;
    Log.error("Error while processing request.", { method, path, response, uuid });
    return response;
  }

  // Handling redirections
  const location = response.headers.get("Location");
  if (location) {
    window.location.href = location;
    return response;
  }

  // Handling backend validation errors. Do nothing for non JSON requests.
  try {
    const data = await response.clone().json();
    if (data.errors?.length) {
      return Promise.reject(data.errors);
    }
  } catch (e) {
    return response;
  }

  return response;
};

const headersWithCSRFToken = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-CSRF-TOKEN": getCSRFToken(),
});

export default requestToServer;
