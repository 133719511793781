import getEnv from "utils/getEnv";
import { PAIN_DIAGRAM_TYPES } from "formBuilder/fb_pain_diagram";

export const defaultConfiguration = () => ({
  dataType: "json",
  disableFields: disableFields(),
  disableHTMLLabels: true,
  disabledActionButtons: ["save"],
  disabledAttrs: ["multiple"],
  controlOrder: ["header"],
  typeUserDisabledAttrs: {
    textEditor: textEditorDisabledAttributes(),
    divider: dividerDisabledAttributes(),
    "checkbox-group": inputDisabledAttributes(),
    "radio-group": inputDisabledAttributes(),
    painDiagram: painDiagramDisabledAttributes(),
  },
  typeUserAttrs: {
    "checkbox-group": checkboxGroupAttributes(),
    painDiagram: painDiagramAttributes(),
  },
});

const disableFields = () => [
  "autocomplete",
  "file",
  "hidden",
  "button",
  "date",
  "textarea",
  "paragraph",
  ...getEnv("painDiagramForCustomFormEnabled", false) ? [] : ["painDiagram"],
];

const textEditorDisabledAttributes = () => [
  "label",
  "description",
  "required",
  "placeholder",
  "access",
];

const dividerDisabledAttributes = () => [
  "required",
  "label",
  "description",
  "required",
  "placeholder",
  "access",
  "value",
  "name",
];

const inputDisabledAttributes = () => [
  "description",
  "toggle",
  "inline",
  "className",
  "access",
  "other",
];

const painDiagramDisabledAttributes = () => [
  "className",
  "required",
  "description",
  "required",
  "placeholder",
  "access",
  "value",
];

const checkboxGroupAttributes = () => ({
  attrRequired: {
    label: "Make Required",
    options: {
      notRequired: "Not Required",
      required: "At least one checkbox required",
      allRequired: "All checkboxes required",
    },
  },
});

const painDiagramAttributes = () => {
  const painDiagramTypeOptions = Object.keys(PAIN_DIAGRAM_TYPES).reduce((types, typeKey) => {
    types[typeKey] = PAIN_DIAGRAM_TYPES[typeKey].label;
    return types;
  }, {});

  return {
    painDiagramType: {
      label: "Pain Diagram Type",
      options: painDiagramTypeOptions,
    },
  };
};
