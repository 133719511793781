import "formBuilder";
import "formBuilder/dist/form-render.min";
import initTranslationsHelper from "utils/initTranslationsHelper";
import initFormBuilderControls from "formBuilder/initFormBuilderControls";
import requestToServer from "utils/requestToServer";
import transformKeysToSnakeCase from "utils/transformKeysToSnakeCase";
import { trackEvent, eventNames } from "utils/EventsTracking";
import { defaultConfiguration } from "./configuration";

const translate = initTranslationsHelper("custom_forms");

const GENERATE_PDF_RESULT = ".generate-pdf-result";
const GENERATE_PDF_ARCHIVE = "#generate-pdf-archive";
const FORM_BUILDER_SELECTOR = "#form-builder";
const FORM_BUILDER_VALUE_INPUT = "#form-builder-value";
const FORM_RENDER_SELECTOR = "#form-render";
const FORM_RENDER_VALUE_CELL = "#form-render-value";
const FORM_RENDER_RESULT = "#form-render-result";
const DOCUMENT_CLASS_ATTR = "document-class";
const LANGUAGE_ATTR = "language";
const LANG_PATH = "/formbuilder/lang/";
const DEFAULT_TEXT_FIELD_MAX_LENGTH = 78;

const CHECKABLE_INPUT_TYPES = ["checkbox-group", "radio-group"];
const SELECTABLE_INPUT_TYPES = ["radio-group", "select"];

const SIGNATURE_CONFIRMATION_FIELD_NAME = "signature-confirmation";

export default class FormBuilder {
  static agreementLabel() {
    const locale = $(FORM_RENDER_VALUE_CELL).data(LANGUAGE_ATTR);
    return translate("agreement", { locale });
  }

  static init(submitButtonId) {
    initFormBuilderControls();
    return new FormBuilder(submitButtonId);
  }

  constructor(submitButtonId) {
    this.submitButtonId = submitButtonId;
    this.$formBuilderContainer = $(FORM_BUILDER_SELECTOR);
    if (this.$formBuilderContainer.length > 0) {
      this.$formBuilder = this.$formBuilderContainer.formBuilder(
        FormBuilder.builderOptions,
      );

      $("form").on("submit", () => {
        const formData = FormBuilder.cleanUpFormData(
          this.$formBuilder.formData,
        );
        $(this.$formBuilderContainer).html("");
        $(FORM_BUILDER_VALUE_INPUT).val(JSON.stringify(formData));
      });
    }

    FormBuilder.disableRenderedFormIfNeeded();

    this.$formRenderContainer = $(FORM_RENDER_SELECTOR);
    if (this.$formRenderContainer.length > 0) {
      this.$formRenderContainer.formRender(FormBuilder.renderOptions($(FORM_RENDER_VALUE_CELL).text()));

      if (this.submitButtonId) {
        $(document).on("touchstart click", `#${this.submitButtonId}`, () => {
          $(FORM_RENDER_RESULT).val(this.dataToSave);
          FormBuilder.trackFormRenderSubmit();
        });
      }
    }

    if (this.submitButtonId) {
      document.getElementById(this.submitButtonId)?.removeAttribute("disabled");
    }

    $(document).on("touchstart click", GENERATE_PDF_RESULT, (event) => {
      const generateButton = $(event.target);
      generateButton.parent().append("<div class='generate-pdf-result-form'></div>");
      const container = generateButton.parent().find(".generate-pdf-result-form");
      const template = generateButton.data("template");
      container.formRender(FormBuilder.renderOptions(template));
      generateButton.val(container.html());
      container.remove();
    });

    $(document).on("touchstart click", GENERATE_PDF_ARCHIVE, (event) => {
      event.preventDefault();
      const formElements = Array.from(document.getElementById("hidden-custom-forms-data-list").children);
      const body = { pdfData: [] };
      formElements.forEach((element) => {
        const pdfButton = $(element);
        pdfButton.parent().append("<div class='generate-pdf-result-form'></div>");
        const container = pdfButton.parent().find(".generate-pdf-result-form");
        const template = pdfButton.data("template");
        container.formRender(FormBuilder.renderOptions(template));
        body.pdfData.push(JSON.stringify({ id: pdfButton.data("id"), pdf_html: container.html() }));
        container.remove();
      });
      const practiceId = window.location.pathname.split("/")[3];
      requestToServer({
        path: `/admin/practices/${practiceId}/custom_forms/download_zip`,
        method: "POST",
        body: transformKeysToSnakeCase(body),
      });
    });
  }

  static trackFormRenderSubmit() {
    trackEvent(eventNames.FORM_RENDER_SUBMIT);
  }

  static cleanUpFormData(data) {
    const jsonFormData = JSON.parse(data);
    FormBuilder.uncheckInputs(jsonFormData);
    FormBuilder.updateOptionValues(jsonFormData);
    return JSON.stringify(jsonFormData);
  }

  static uncheckInputs(data) {
    data.forEach((element) => {
      if (CHECKABLE_INPUT_TYPES.includes(element.type)) {
        element.values.forEach((value) => {
          value.selected = false;
        });
      }
    });
    return data;
  }

  static updateOptionValues(data) {
    data.forEach((element) => {
      if (SELECTABLE_INPUT_TYPES.includes(element.type)) {
        element.values = element.values.filter(
          (option) => !(option.label === "" && option.value === ""),
        );

        element.values.forEach((option) => {
          if (option.value === "") {
            option.value = option.label.toLowerCase();
          }
        });
      }
    });
    return data;
  }

  static get builderOptions() {
    const formData = FormBuilder.getFormData($(FORM_BUILDER_VALUE_INPUT).val());
    return Object.assign(defaultConfiguration(), {
      i18n: {
        location: LANG_PATH,
      },
      typeUserEvents: {
        "checkbox-group": FormBuilder.checkboxUserEvents(formData),
      },
      formData,
    });
  }

  static get showSignatureConfirmationField() {
    return $(FORM_RENDER_VALUE_CELL).data(DOCUMENT_CLASS_ATTR) === "admin";
  }

  static checkboxUserEvents(data) {
    return {
      onadd: (field) => {
        const attrRequired = FormBuilder.getAttrRequiredForField(field, data);
        FormBuilder.getAttrRequiredField(field).val(attrRequired);
        FormBuilder.onAttrRequiredChange(field, attrRequired);

        FormBuilder.getRequiredField(field)
          .closest(".required-wrap")
          .hide();

        FormBuilder.attachCheckboxAttrRequiredChangeHandler(field);
      },
    };
  }

  static getAttrRequiredForField(field, data) {
    const name = $(field)
      .find(".fld-name")
      .val();
    const fieldData = JSON.parse(data || "[]").find(
      (fd) => fd.name === name,
    );
    return (fieldData && fieldData.attrRequired) || "required";
  }

  static attachCheckboxAttrRequiredChangeHandler(field) {
    FormBuilder.getAttrRequiredField(field).on("change", (e) => {
      FormBuilder.onAttrRequiredChange(field, e.target.value);
    });
  }

  static onAttrRequiredChange(field, value) {
    if (value === "required") {
      FormBuilder.getRequiredField(field).prop("checked", true);
      $(field)
        .find(".required-asterisk")
        .show();
    } else {
      FormBuilder.getRequiredField(field).prop("checked", false);
      $(field)
        .find(".required-asterisk")
        .hide();
    }
  }

  static getRequiredField(field) {
    return $(field).find(".fld-required");
  }

  static getAttrRequiredField(field) {
    return $(field).find(".fld-attrRequired");
  }

  static renderOptions(formDataValue) {
    let formData = FormBuilder.getFormData(formDataValue);
    formData = FormBuilder.addMaxLengthToTextFields(formData);

    if (FormBuilder.showSignatureConfirmationField) {
      formData = FormBuilder.addAgreementCheckboxToForm(formData);
    }

    return {
      dataType: "json",
      formData,
      i18n: {
        location: LANG_PATH,
      },
      notify: {
        success: () => {
          FormBuilder.disableRenderedFormIfNeeded();
          FormBuilder.setCustomValidityForCheckboxes();
        },
      },
    };
  }

  static addMaxLengthToTextFields(formData) {
    const controls = JSON.parse(formData);

    controls.forEach((control) => {
      if (control.type === "text" &&
          (control.maxlength === undefined || control.maxlength > DEFAULT_TEXT_FIELD_MAX_LENGTH)) {
        control.maxlength = DEFAULT_TEXT_FIELD_MAX_LENGTH;
      }
    });

    return JSON.stringify(controls);
  }

  static addAgreementCheckboxToForm(formData) {
    if (formData === "[]") {
      return `[${agreementCheckbox}]`;
    }
    return `${formData.slice(0, -1)},${agreementCheckbox}]`;
  }

  static disableRenderedFormIfNeeded() {
    const form = $(".client-form");
    if (form.hasClass("disabled")) {
      form.find("input, select").prop("disabled", true);
    }
  }

  static setCustomValidityForCheckboxes() {
    $(".client-form input[attr-required='allRequired']").attr(
      "required",
      "required",
    );
  }

  static getFormData(formDataValue) {
    return (formDataValue && JSON.parse(formDataValue)) || "[]";
  }

  static setCheckedInputsValues(_index, input) {
    if (input.checked) {
      input.checked = true;
      input.setAttribute("checked", "checked");
    } else {
      input.checked = false;
      input.removeAttribute("checked");
    }
  }

  static setSelectFieldsValues(_indexFromOuterEach, select) {
    const selectValue = select.value;
    $(select)
      .find(`option[value="${selectValue}"]`)
      .each((_index, option) => {
        option.setAttribute("selected", "selected");
      });
  }

  get dataToSave() {
    this.$formRenderContainer
      .find("[type='text'], [type='number']")
      .each((_index, input) => {
        input.setAttribute("value", input.value);
      });
    this.$formRenderContainer
      .find("[type='checkbox'], [type='radio']")
      .each(FormBuilder.setCheckedInputsValues);
    this.$formRenderContainer.find("select").each(FormBuilder.setSelectFieldsValues);

    const htmlToSave = $("<div>").html(this.$formRenderContainer.html());

    return htmlToSave.html();
  }
}

const agreementCheckbox = `${JSON.stringify({
  type: "divider",
  name: SIGNATURE_CONFIRMATION_FIELD_NAME,
})},
${JSON.stringify({
    type: "checkbox-group",
    label: "",
    name: SIGNATURE_CONFIRMATION_FIELD_NAME,
    attrRequired: "allRequired",
    values: [
      {
        label: FormBuilder.agreementLabel(),
        value: true,
        selected: false,
      },
    ],
  })}`;
